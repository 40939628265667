.scanner-container {
  position: relative;
  width: 80vw;
  max-width: 80vh;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.inner-frame-scanner {
  border: 1px solid red;
  background-color: transparent;
  position: absolute;
  width: 60%;
  height: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
}

.video-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
